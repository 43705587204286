import { template as template_7942a8808a0e47a2a19b56e0729289a0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_7942a8808a0e47a2a19b56e0729289a0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
