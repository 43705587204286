import { template as template_6b6a55ffd66f41f0bf021e261b156e1b } from "@ember/template-compiler";
const WelcomeHeader = template_6b6a55ffd66f41f0bf021e261b156e1b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
